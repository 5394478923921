<template>
  <div class="page_container">
    <div class="page_head">
      <div class="page_head-title">{{ head_title }}</div>
      <div class="page_head-buttons">

        <b-form-select v-model="lesson_head.class" class="page_head-btn" @change="onClassChange" value-field="id"
          text-field="value" :options="classes">
          <template #first>
            <b-form-select-option :value="null" disabled>CLASS</b-form-select-option>
          </template>
        </b-form-select>

        <b-form-select v-model="lesson_head.subject" class="page_head-btn" value-field="id" text-field="materiaDesc"
          :options="filteredSubject">
          <template #first>
            <b-form-select-option :value="null" disabled>SUBJECT</b-form-select-option>
          </template>
        </b-form-select>

        <b-form-input v-model="lesson_head.topic" class="page_head-btn" placeholder="TOPIC">
        </b-form-input>
      </div>
      <div class="page_head-duplicate">Duplicate</div>
    </div>
    <div class="page_content">
      <div class="page_lesson" :class="{ 'full_page': right_bar }">
        <h4 class="page_subtitle">LESSON’S TITLE</h4>
        <div class="page_title-container">
          <h3 class="page_title" v-if="!titleStatus">{{ lesson_title }}</h3>
          <b-form-input v-else v-model="lesson_title"></b-form-input>
          <div v-if="!titleStatus" class="edit_btn" @click="editLesson()"></div>
          <div v-else @click="changeTitle()" class="change_btn">Change</div>
        </div>

        <div class="page_lesson-data">
          <div class="goals" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header class="p-0" role="tab">
                <b-button block v-b-toggle.accord_1 variant="" id="goal_btn" class="goal_btn">PURPOSE and INFORMATION
                  <div class="arrow_down"></div>
                </b-button>
              </b-card-header>
              <b-collapse id="accord_1" accordion="my-accordion" role="tabpanel" class="collapse_panel">
                <b-card-body>
                  <b-card-text>
                    <span class="collapse_panel-text">Teacher’s purpose for the lesson and information*:</span>
                    <b-form-textarea id="textarea" v-model="teacher_purpose"
                      placeholder="Enter what the general purpose of the lesson is and information regarding it..."
                      rows="6" max-rows="8"></b-form-textarea>
                    <span class="collapse_panel-text">Student’s goals for the lesson and information*:</span>
                    <b-form-textarea id="textarea" v-model="student_goals"
                      placeholder="Enter what the general student’s goals of the lesson is and information regarding it..."
                      rows="6" max-rows="8"></b-form-textarea>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>





          <!-- TEAMS -->
          <div class="goals" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header class="p-0" role="tab">
                <b-button block v-b-toggle.team_accord variant="" id="goal_btn" class="goal_btn">TEAMS
                  <div class="arrow_down"></div>
                </b-button>
              </b-card-header>
              <b-collapse id="team_accord" accordion="team_accord" role="tabpanel" class="collapse_panel">
                <b-card-body>
                  <div class="team" role="tablist">
                    <b-card no-body class="mb-2" v-for="(team, index) in teams" :key="index + 1">
                      <b-card-header class="p-0" role="tab">
                        <b-button block v-b-toggle="`accordion-${index}`" variant="" class="goal_btn"
                          @click="changeTeam()">
                          {{ team.team_name }}
                          <div class="arrow_down"></div>
                        </b-button>
                      </b-card-header>
                      <b-collapse :id="`accordion-${index}`" accordion="my-accordion" role="tabpanel"
                        class="collapse_panel">
                        <b-card-body>
                          <b-card-text class="students_container">
                            <b-form-input v-model="search_member" class="search_member"></b-form-input>
                            <div class="add_student" :class="{ 'added': checkActiveAllStudents(team.members) }">
                              <div>ALL CLASSROOM&nbsp;</div>
                              <div v-if="!checkActiveAllStudents(team.members)" class="add_student-btn"
                                @click="addAllStudents(team)"></div>
                              <div v-else class="remove_student-btn" @click="removeAllStudents(team)"></div>
                            </div>
                            <div class="all_members" v-if="team.members.length">
                              <span v-for="member of team.members">{{ member.cognome }} {{ member.nome }},&nbsp;</span>
                            </div>
                            <div class="add_student" :class="{ 'added': checkActiveStudent(team.members, student.id) }"
                              v-for="student in filteredStudents"
                              v-if="student.selected == null || student.selected == `${team.id}--${student.id}`">
                              <div>{{ student.cognome }} {{ student.nome }}</div>
                              <div v-if="!checkActiveStudent(team.members, student.id)" class="add_student-btn"
                                @click="addStudent(team, student)"></div>
                              <div v-else class="remove_student-btn" @click="removeStudent(team, student)"></div>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>

                  <div class="add_item" @click="addTeamModal()" id="add_team-btn">
                    <div>Add team</div>
                    <div class="add_item-btn"></div>
                  </div>
                  <span v-if="addTeamError" class="text_danger">{{ addTeamError }}</span>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- TEAMS -->




          <div class="goals" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header class="p-0" role="tab">
                <b-button block v-b-toggle.accord_2 variant="" id="goal_btn" class="goal_btn">IEXS LESSON PROTOCOL
                  <div class="arrow_down"></div>
                </b-button>
              </b-card-header>
              <b-collapse id="accord_2" accordion="my-accordion" role="tabpanel" class="collapse_panel">
                <b-card-body>
                  <div style='display: flex; margin-bottom: 10px;'>
                    <div style="display: flex; width: 50%;margin-top: 10px;padding-left: 10px;">
                      <span>DIDACTICAL Objective*:</span>
                    </div>

                    <span v-if="didactical_error" class="text_danger">Didactical Objective cannot be empty.</span>

                    <div style="display: flex; justify-content: end; width: 50%;margin-right: 10px">
                      <b-form-input placeholder="Add Title" v-model="goal.name" style="width: 200px;"></b-form-input>
                    </div>
                  </div>
                  <div style="padding-left: 10px; padding-right: 10px;">
                    <b-card-body
                      style="border-radius: 10px; border: 1px solid #11334D;background-color: white; padding: 15px 22px;">


                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="4" max-rows="4"style="resize: none;margin:0;" v-model="goal.didactical_objective_content" placeholder="Enter both the individual and team objectives of the lesson. What do you want them to learn at the end of the lesson?"></b-form-textarea>
                      </div>

                      <!-- SELECT TEAM -->
                      <div class="team">
                        <b-card no-body>
                          <b-card-header class="p-0" role="tab">
                            <b-button block v-b-toggle="`accordion-c`" variant="" class="goal_btn">
                              <div>Assigned team</div>
                              <div class="arrow_down"></div>
                            </b-button>
                          </b-card-header>

                          <b-collapse :id="`accordion-c`" accordion="my-accordion2" role="tabpanel">
                            <b-card-body>
                              <b-card-text class="my-1">
                                <div class="add_student"
                                  :class="{ 'added': checkActiveTeam(goal.didactical_assigned_teams, team.id) }"
                                  v-for="team in teams">
                                  <div>{{ team.team_name }}</div>
                                  <div v-if="!checkActiveMethod(goal.didactical_assigned_teams, team.id)"
                                    class="add_student-btn" @click="addDidacticalTeam(goal, team)"></div>
                                  <div v-else class="remove_student-btn"
                                    @click="removeDidacticalTeam(goal, team)">
                                  </div>
                                </div>
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                      <!-- SELECT TEAM -->

                      <div v-if="true" class="team" role="tablist">
                        <b-card no-body>
                          <b-card-header class="p-0" role="tab">
                            <b-button block v-b-toggle="`metod-accordion`" variant="" class="goal_btn">
                              <div>Methodology</div>
                              <div class="arrow_down"></div>
                            </b-button>
                          </b-card-header>
                          <b-collapse :id="`metod-accordion`" accordion="metod-accordion" role="tabpanel"
                            class="collapse_panel">
                            <b-card-body>
                              <b-card-text class="students_container">



                                <div class="add_student" v-for="method in didactical_models"
                                  :class="{ 'added': goal.didactical_methodologies.some(s => s.id === method.id) }">
                                  <div>{{ method.name }}</div>
                                  <div v-if="!goal.didactical_methodologies.some(s => s.id === method.id)"
                                    class="add_student-btn" @click="addDidacticalMethod(method, goal)"></div>
                                  <div v-else class="remove_student-btn" @click="removeDidacticalMethod(method, goal)"></div>
                                </div>

                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>


                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="1" max-rows="1"style="resize: none;margin:0;" v-model="goal.didactical_award" placeholder="Award for achievement"></b-form-textarea>
                      </div>
                      
                      <div style="display: flex; position:sticky; height: 70px; flex-wrap: wrap; justify-content: center;margin-top: 20px; margin-bottom: 20px">
                        <div style="margin: 10px; height: min-content">
                          <b-form-checkbox v-model="goal.didactical_specific">Specific?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.didactical_misurable">Misurable?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.didactical_achievable">Achievable?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.didactical_relevant">Relevant?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.didactical_time_based">Time based?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.didactical_stated">Stated to the class?</b-form-checkbox>
                        </div>
                      </div>




                    </b-card-body>
                  </div>


                  <div style='display: flex;margin-bottom: 10px;'>
                    <div style="display: flex; width: 50%;margin-top: 10px;padding-left: 10px;">
                      <span>EMOTIONAL Objective*:</span>
                    </div>
                  </div>
                  <div style="padding-left: 10px; padding-right: 10px;">
                    <b-card-body
                      style="border-radius: 10px; border: 1px solid #11334D;background-color: white; padding: 15px 22px;">

                      <span v-if="emotional_error" class="text_danger">Emotional Objective cannot be empty.</span>

                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="4" max-rows="4"style="resize: none;margin:0;" v-model="goal.emotional_objective_content" placeholder="Enter both the individual and team objectives of the lesson. What do you want them to take home at the end of your lesson. How do you want the mood to be before, during and after yours intervention?"></b-form-textarea>
                      </div>

                      <!-- SELECT TEAM -->
                      <div class="team">
                        <b-card no-body>
                          <b-card-header class="p-0" role="tab">
                            <b-button block v-b-toggle="`emotional-accordion-c`" variant="" class="goal_btn">
                              <div>Assigned team</div>
                              <div class="arrow_down"></div>
                            </b-button>
                          </b-card-header>

                          <b-collapse :id="`emotional-accordion-c`" accordion="emotional-my-accordion2" role="tabpanel">
                            <b-card-body>
                              <b-card-text class="my-1">
                                <div class="add_student"
                                  :class="{ 'added': checkActiveTeam(goal.emotional_assigned_teams, team.id) }"
                                  v-for="team in teams">
                                  <div>{{ team.team_name }}</div>
                                  <div v-if="!checkActiveMethod(goal.emotional_assigned_teams, team.id)"
                                    class="add_student-btn" @click="addEmotionalTeam(goal, team)"></div>
                                  <div v-else class="remove_student-btn"
                                    @click="removeEmotionalTeam(goal, team)">
                                  </div>
                                </div>
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                      <!-- SELECT TEAM -->


                      <div v-if="true" class="team" role="tablist">
                        <b-card no-body>
                          <b-card-header class="p-0" role="tab">
                            <b-button block v-b-toggle="`emotional-metod-accordion`" variant="" class="goal_btn">
                              <div>Methodology</div>
                              <div class="arrow_down"></div>
                            </b-button>
                          </b-card-header>
                          <b-collapse :id="`emotional-metod-accordion`" accordion="emotional-metod-accordion" role="tabpanel"
                            class="collapse_panel">
                            <b-card-body>
                              <b-card-text class="students_container">



                                <div class="add_student" v-for="method in didactical_models"
                                  :class="{ 'added': goal.emotional_methodologies.some(s => s.id === method.id) }">
                                  <div>{{ method.name }}</div>
                                  <div v-if="!goal.emotional_methodologies.some(s => s.id === method.id)"
                                    class="add_student-btn" @click="addEmotionalMethod(method, goal)"></div>
                                  <div v-else class="remove_student-btn" @click="removeEmotionalMethod(method, goal)"></div>
                                </div>

                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>

 
                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="1" max-rows="1"style="resize: none;margin:0" v-model="goal.emotional_award" placeholder="Award for achievement"></b-form-textarea>
                      </div>
                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="1" max-rows="1"style="resize: none;margin:0" v-model="goal.emotional_mood_needed" placeholder="Mood needed"></b-form-textarea>
                      </div>
                      <div
                        style="display: flex; position:sticky; height: 70px; flex-wrap: wrap; justify-content: center;margin-top: 20px; margin-bottom: 20px">
                        <div style="margin: 10px; height: min-content">
                          <b-form-checkbox v-model="goal.emotional_specific">Specific?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.emotional_misurable">Misurable?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.emotional_achievable">Achievable?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.emotional_relevant">Relevant?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.emotional_time_based">Time based?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="goal.emotional_stated">Stated to the class?</b-form-checkbox>
                        </div>
                      </div>
                    </b-card-body>


                    <!-- DURATA E TAGS -->
                    <div style="margin-top: 10px;">
                      <span class="collapse_panel-text">Duration</span>
                      <div class="time_picker-container">
                        <b-form-timepicker id="timepicker-placeholder" v-model="goal.start_time"
                          placeholder="Choose a time" locale="en"></b-form-timepicker>
                        <b-form-select v-model="goal.duration" :options="minute_options"></b-form-select>
                      </div>
                      <span class="collapse_panel-text">Tag:</span>
                      <b-form-tags class="tags_input" input-id="tags-pills" v-model="goal.selected_tags"
                        tag-variant="primary" tag-pills separator=" " placeholder="Add tag"></b-form-tags>
                    </div>
                    <!-- DURATA E TAGS -->

                  </div>


                  <div style="display: flex; width: 100%; justify-content: end">
                    <b-button style="width: 180px;" class="add_btn" :disabled="!goal.duration || !goal.start_time" @click="addGoal()">Add</b-button>
                  </div>
                </b-card-body>



              </b-collapse>
            </b-card>
          </div>


          <div>
            <b-card class="mb-2" no-body v-for="(lesson_goal, index) in filteredGoals">
              <b-card class="mb-2" no-body>
                <b-card-header class="p-0" role="tab">
                  <b-button block v-b-toggle="`accordionn-goal-${index + 1}`" variant="" class="event_btn">
                    <div class="d-flex align-items-center">
                      {{ lesson_goal.start_time || '' }}
                    </div>
                    <div v-if="lesson_goal">
                      <div class="goal_title" v-b-tooltip.hover="{ variant: 'info' }"
                        @click.stop="editGoal(lesson_goal)">{{ lesson_goal.name }}
                      </div>
                    </div>
                    <div class="d-flex align-items-center arrow_down-min">
                      <div>{{ lesson_goal.duration }} min</div>
                      <div class="arrow_down"></div>
                    </div>
                  </b-button>
                </b-card-header>
                
                <b-collapse :id="`accordionn-goal-${index + 1}`" accordion="my-accordion" role="tabpanel" class="collapse_panel">
                <b-card-body>
                  <div style='display: flex;margin-bottom: 10px;'>
                    <div style="display: flex; width: 50%;margin-top: 10px;padding-left: 10px;">
                      <span>DIDACTICAL Objective*:</span>
                    </div>
                    <div style="display: flex; justify-content: end; width: 50%;margin-right: 10px">
                      <b-form-input v-model="lesson_goal.name" style="width: 200px;"></b-form-input>
                    </div>
                  </div>
                  <div style="padding-left: 10px; padding-right: 10px;">
                    <b-card-body
                      style="border-radius: 10px; border: 1px solid #11334D;background-color: white; padding: 15px 22px;">
                      
                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="4" max-rows="4"style="resize: none;margin:0;" v-model="lesson_goal.didactical_objective_content" placeholder="Enter both the individual and team objectives of the lesson. What do you want them to learn at the end of the lesson?"></b-form-textarea>
                      </div>

                      <!-- SELECT TEAM -->
                      <div class="team">
                        <b-card no-body>
                          <b-card-header class="p-0" role="tab">
                            <b-button block v-b-toggle="`accordion-c`" variant="" class="goal_btn">
                              <div>Assigned team</div>
                              <div class="arrow_down"></div>
                            </b-button>
                          </b-card-header>

                          <b-collapse :id="`accordion-c`" accordion="my-accordion2" role="tabpanel">
                            <b-card-body>
                              <b-card-text class="my-1">
                                <div class="add_student"
                                  :class="{ 'added': checkActiveTeam(lesson_goal.didactical_assigned_teams, team.id) }"
                                  v-for="team in teams">
                                  <div>{{ team.team_name }}</div>
                                  <div v-if="!checkActiveMethod(lesson_goal.didactical_assigned_teams, team.id)"
                                    class="add_student-btn" @click="addDidacticalTeam(lesson_goal, team)"></div>
                                  <div v-else class="remove_student-btn"
                                    @click="removeDidacticalTeam(lesson_goal, team)">
                                  </div>
                                </div>
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                      <!-- SELECT TEAM -->

                      <div v-if="true" class="team" role="tablist">
                        <b-card no-body>
                          <b-card-header class="p-0" role="tab">
                            <b-button block v-b-toggle="`metod-accordion`" variant="" class="goal_btn">
                              <div>Methodology</div>
                              <div class="arrow_down"></div>
                            </b-button>
                          </b-card-header>
                          <b-collapse :id="`metod-accordion`" accordion="metod-accordion" role="tabpanel"
                            class="collapse_panel">
                            <b-card-body>
                              <b-card-text class="students_container">



                                <div class="add_student" v-for="method in didactical_models"
                                  :class="{ 'added': lesson_goal.didactical_methodologies.some(s => s.id === method.id) }">
                                  <div>{{ method.name }}</div>
                                  <div v-if="!lesson_goal.didactical_methodologies.some(s => s.id === method.id)"
                                    class="add_student-btn" @click="addDidacticalMethod(method, lesson_goal)"></div>
                                  <div v-else class="remove_student-btn" @click="removeDidacticalMethod(method, lesson_goal)"></div>
                                </div>

                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>

                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="1" max-rows="1"style="resize: none;margin:0;" v-model="lesson_goal.didactical_award" placeholder="Award for achievement"></b-form-textarea>
                      </div>
                      
                      <div style="display: flex; position:sticky; height: 70px; flex-wrap: wrap; justify-content: center;margin-top: 20px; margin-bottom: 20px">
                        <div style="margin: 10px; height: min-content">
                          <b-form-checkbox v-model="lesson_goal.didactical_specific">Specific?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.didactical_misurable">Misurable?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.didactical_achievable">Achievable?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.didactical_relevant">Relevant?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.didactical_time_based">Time based?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.didactical_stated">Stated to the class?</b-form-checkbox>
                        </div>
                      </div>




                    </b-card-body>
                  </div>


                  <div style='display: flex;margin-bottom: 10px;'>
                    <div style="display: flex; width: 50%;margin-top: 10px;padding-left: 10px;">
                      <span>EMOTIONAL Objective*:</span>
                    </div>
                  </div>
                  <div style="padding-left: 10px; padding-right: 10px;">
                    <b-card-body
                      style="border-radius: 10px; border: 1px solid #11334D;background-color: white; padding: 15px 22px;">
                      
                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="4" max-rows="4"style="resize: none;margin:0;" v-model="lesson_goal.emotional_objective_content" placeholder="Enter both the individual and team objectives of the lesson. What do you want them to take home at the end of your lesson. How do you want the mood to be before, during and after yours intervention?"></b-form-textarea>
                      </div>

                      <!-- SELECT TEAM -->
                      <div class="team">
                        <b-card no-body>
                          <b-card-header class="p-0" role="tab">
                            <b-button block v-b-toggle="`emotional-accordion-c`" variant="" class="goal_btn">
                              <div>Assigned team</div>
                              <div class="arrow_down"></div>
                            </b-button>
                          </b-card-header>

                          <b-collapse :id="`emotional-accordion-c`" accordion="emotional-my-accordion2" role="tabpanel">
                            <b-card-body>
                              <b-card-text class="my-1">
                                <div class="add_student"
                                  :class="{ 'added': checkActiveTeam(lesson_goal.emotional_assigned_teams, team.id) }"
                                  v-for="team in teams">
                                  <div>{{ team.team_name }}</div>
                                  <div v-if="!checkActiveMethod(lesson_goal.emotional_assigned_teams, team.id)"
                                    class="add_student-btn" @click="addEmotionalTeam(lesson_goal, team)"></div>
                                  <div v-else class="remove_student-btn"
                                    @click="removeEmotionalTeam(lesson_goal, team)">
                                  </div>
                                </div>
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                      <!-- SELECT TEAM -->


                      <div v-if="true" class="team" role="tablist">
                        <b-card no-body>
                          <b-card-header class="p-0" role="tab">
                            <b-button block v-b-toggle="`emotional-metod-accordion`" variant="" class="goal_btn">
                              <div>Methodology</div>
                              <div class="arrow_down"></div>
                            </b-button>
                          </b-card-header>
                          <b-collapse :id="`emotional-metod-accordion`" accordion="emotional-metod-accordion" role="tabpanel"
                            class="collapse_panel">
                            <b-card-body>
                              <b-card-text class="students_container">



                                <div class="add_student" v-for="method in didactical_models"
                                  :class="{ 'added': lesson_goal.emotional_methodologies.some(s => s.id === method.id) }">
                                  <div>{{ method.name }}</div>
                                  <div v-if="!lesson_goal.emotional_methodologies.some(s => s.id === method.id)"
                                    class="add_student-btn" @click="addEmotionalMethod(method, lesson_goal)"></div>
                                  <div v-else class="remove_student-btn" @click="removeEmotionalMethod(method, lesson_goal)"></div>
                                </div>

                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>

                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="1" max-rows="1"style="resize: none;margin:0;" v-model="lesson_goal.emotional_award" placeholder="Award for achievement"></b-form-textarea>
                      </div>
                      <div style="padding-top: 10px; padding-bottom: 10px;">
                        <b-form-textarea class="card" rows="1" max-rows="1"style="resize: none;margin:0;" v-model="lesson_goal.emotional_mood_needed" placeholder="Mood needed"></b-form-textarea>
                      </div>
                      <div
                        style="display: flex; position:sticky; height: 70px; flex-wrap: wrap; justify-content: center;margin-top: 20px; margin-bottom: 20px">
                        <div style="margin: 10px; height: min-content">
                          <b-form-checkbox v-model="lesson_goal.emotional_specific">Specic?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.emotional_misurable">Misurable?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.emotional_achievable">Achievable?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.emotional_relevant">Relevant?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.emotional_time_based">Time based?</b-form-checkbox>
                        </div>
                        <div style="margin: 10px; margin-bottom: 0;height: min-content">
                          <b-form-checkbox v-model="lesson_goal.emotional_stated">Stated to the class?</b-form-checkbox>
                        </div>
                      </div>
                    </b-card-body>


                    <!-- DURATA E TAGS -->
                    <div style="margin-top: 10px;">
                      <span class="collapse_panel-text">Duration</span>
                      <div class="time_picker-container">
                        <b-form-timepicker id="timepicker-placeholder" v-model="lesson_goal.start_time"
                          placeholder="Choose a time" locale="en"></b-form-timepicker>
                        <b-form-select v-model="lesson_goal.duration" :options="minute_options"></b-form-select>
                      </div>
                      <span class="collapse_panel-text">Tag:</span>
                      <b-form-tags class="tags_input" input-id="tags-pills" v-model="lesson_goal.selected_tags"
                        tag-variant="primary" tag-pills separator=" " placeholder="Add tag"></b-form-tags>
                    </div>
                    <!-- DURATA E TAGS -->

                  </div>


                </b-card-body>



              </b-collapse>
              </b-card></b-card>

          </div>






          <div class="date_picker">
            <b-input-group class="mb-3">

              <b-input-group-append >
                <b-form-datepicker v-model="lesson_date" right placeholder="Lesson Date (MM-DD-YYYY)" style="background-color: transparent;"
                  :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                  aria-controls="example-input" @context="onContext"></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>


          </div>

          <div class="add_item" style="margin:0" id="link-btn" @click="linkModal()">
            <div>Classroom lesson’s link</div>
            <div class="add_item-btn"></div>
          </div>
          <p v-if="classroom_link" class="class_link">{{ classroom_link }}</p>
          <div class="goals" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header class="p-0" role="tab">
                <b-button block v-b-toggle.accord_3 variant="" class="goal_btn">EFFICIENCY AND RESULT
                  <div class="arrow_down"></div>
                </b-button>
              </b-card-header>
              <b-collapse id="accord_3" accordion="my-accordion" role="tabpanel" class="collapse_panel">
                <b-card-body>
                  <b-card-text>
                    <span class="collapse_panel-text">Efficiency <span class="efficiency_value">&nbsp;{{
                      efficiency_and_result.efficiency }}</span></span>
                    <div class="range_container">
                      <b-form-input type="range" v-model="efficiency_and_result.efficiency" class="form-range" min="0"
                        max="10"></b-form-input>
                      <div class="tick_container">
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                        <div class="tick"></div>
                      </div>
                    </div>
                    <span class="collapse_panel-text">What went good?</span>
                    <b-form-input v-model="efficiency_and_result.good" class="collapse_panel-input"></b-form-input>
                    <span class="collapse_panel-text">What can i improve?</span>
                    <b-form-input v-model="efficiency_and_result.improve" class="collapse_panel-input"></b-form-input>
                    <span class="collapse_panel-text">Notes:</span>
                    <b-form-input v-model="efficiency_and_result.notes" class="collapse_panel-input"></b-form-input>
                    <div class="add_btn-container">
                      <b-button class="add_btn" v-b-toggle.accord_3>Add</b-button>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="expert_item">
            <div>ASK TO AN EXPERT</div>
            <div class="arrow_right-btn"></div>
          </div>

          <div class="card share_lesson">
            <b-form-checkbox id="checkbox-1" v-model="share_lesson" name="checkbox-1" value="1" unchecked-value="0">
              <span class="collapse_panel-text">Share the lesson</span>
            </b-form-checkbox>

          </div>
        </div>
        <div class="save_btn-container">
          <b-button class="save_btn" @click="saveLesson()">Save</b-button>
          <div>
            <span v-for="error in saveError" class="text_danger" style="margin-bottom:0;margin-left: 10px; display: block">{{ error }}</span>
          </div>
          <div v-if="success_msg" class="success_message">{{ success_msg }}</div>
        </div>
      </div>

      <div class="page_right-bar" :class="{ 'open_bar': right_bar }">
        <div class="bar_btn" @click="openBar()">
          <svg v-if="right_bar" width="24" height="111" viewBox="0 0 24 111" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.3998 110H5.7998C3.2998 110 1.2998 108 1.2998 105.5V5.80005C1.2998 3.30005 3.2998 1.30005 5.7998 1.30005H18.3998C20.8998 1.30005 22.8998 3.30005 22.8998 5.80005V105.5C22.8998 108 20.8998 110 18.3998 110Z"
              stroke="#97D5C9" stroke-width="0.8109" stroke-miterlimit="10" />
            <path d="M16.9 71.7001L6.5 55.8L17 39.8" stroke="#97D5C9" stroke-width="0.4417" stroke-miterlimit="10" />
          </svg>
          <svg v-else width="22" height="110" viewBox="0 0 22 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.0002 0.5H16.4002C18.8002 0.5 20.7002 2.39999 20.7002 4.79999V104.8C20.7002 107.2 18.8002 109.1 16.4002 109.1H5.0002C2.6002 109.1 0.700195 107.2 0.700195 104.8V4.79999C0.700195 2.49999 2.6002 0.5 5.0002 0.5Z"
              stroke="#97D5C9" stroke-width="0.7835" stroke-miterlimit="10" />
            <path d="M6.2002 38.9L16.0002 54.8L6.2002 70.8" stroke="#97D5C9" stroke-width="0.4267"
              stroke-miterlimit="10" />
          </svg>
        </div>
        <div class="didactic_content" :class="{ 'hide_content': right_bar }">
          <div class="didactic_title">DIDACTICAL MODEL AND CONTENTS</div>
          <div class="didactic_search">
            <label>Search on:</label>
            <b-form-group class="search_radios">
              <b-form-radio-group class="radio_group" id="radio-group-1" v-model="selected_search" :options="options"
                name="radio-inline"></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="search_input-container">
            <b-form-input v-model="search_data">
            </b-form-input>
            <div class="search_icon"></div>
          </div>
          <div class="educative_title">Didactical models</div>
          <div class="models_container">
            <div class="model_item" id="method-btn" v-for="method in didactical_models" @click="methodModal(method)">
              {{ method.name }}
            </div>
          </div>

        </div>
      </div>

      <!--add team modal-->
      <b-modal ref="team-modal" centered size="md" hide-header hide-footer>
        <div class="modal_content">
          <div class="modal_title">
            Add New Team
          </div>
          <div class="modal_input">
            <b-form-input v-model="new_team" placeholder="Team name"></b-form-input>
          </div>

          <div class="modal_btn">
            <b-button class="cancel_btn mx-2" @click="addTeamModal()">Cancel</b-button>
            <b-button class="add_btn mx-2" :disabled="!new_team" @click="addNewTeam()">Add team</b-button>
          </div>

        </div>
      </b-modal>
      <!--add team modal-->

      <!--classroom link modal-->
      <b-modal ref="link-modal" centered size="md" hide-header hide-footer>
        <div class="modal_content">
          <div class="modal_title">
            Add New Classroom Link
          </div>
          <div class="modal_input">
            <b-form-input v-model="classroom_link" placeholder="Link"></b-form-input>
          </div>

          <div class="modal_btn">
            <b-button class="cancel_btn mx-2" @click="linkModal()">Cancel</b-button>
            <b-button class="add_btn mx-2" @click="addLink()">Add link</b-button>
          </div>

        </div>
      </b-modal>
      <!--classroom link modal-->

      <!--Method modal-->
      <b-modal ref="method-modal" centered size="lg" hide-header hide-footer>
        <div class="modal_content" v-if="false">
          <div class="modal_title">
            {{ selectedMethod.name }}
          </div>
          <div class="modal_input">
            <label>Methodological description</label>
            <div class="method_item">{{ selectedMethod.methodological_description || '...' }}</div>
            <label>Teacher and student’s role</label>
            <div class="method_item">{{ selectedMethod.teachers_and_students_roles || '...' }}</div>
            <label>Class setting</label>
            <div class="method_item">{{ selectedMethod.class_settings || '...' }}</div>
            <label>Evaluation methods</label>
            <div class="method_item">{{ selectedMethod.evaluation_methods || '...' }}</div>
            <label>Tools needed</label>
            <div class="method_item">{{ selectedMethod.tools_needed || '...' }}</div>
            <label>Criticality and possible solutions</label>
            <div class="method_item">{{ selectedMethod.criticality_and_possible_solutions || '...' }}</div>
            <label>Notes:</label>
            <div class="method_item">{{ selectedMethod.extra_content || '...' }}</div>

            <!--            <b-form-input v-model="new_team" placeholder="Team name"></b-form-input>-->
          </div>

          <div class="modal_btn">
            <b-button class="cancel_btn mx-2" @click="methodModal()">Cancel</b-button>
          </div>

        </div>
      </b-modal>
      <!--Method modal-->

    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import './NewLesson.scss';

export default {
  name: "newlesson",
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)

    return {
      saveError: [],
      didactical_error: false,
      emotional_error: false,
      min: minDate,
      lesson_head: {
        class: null,
        subject: null,
        topic: null,
      },
      classes: [],
      subject: [],
      filteredSubject: [],
      head_title: "New lesson",
      lesson_title: 'lesson 1',
      titleStatus: false,
      right_bar: true,
      addTeamError: null,
      goal_btn_status: false,
      teacher_purpose: '',
      student_goals: '',
      goal: {
        name: '',
        didactical_objective_content: '',
        didactical_methodologies: [],
        didactical_assigned_teams: [],
        didactical_award: '',
        didactical_specific: false,
        didactical_misurable: false,
        didactical_achievable: false,
        didactical_relevant: false,
        didactical_time_based: false,
        didactical_stated: false,

        emotional_objective_content: '',
        emotional_methodologies: [],
        emotional_assigned_teams: [],
        emotional_award: '',
        emotional_mood_needed: '',
        emotional_specific: false,
        emotional_misurable: false,
        emotional_achievable: false,
        emotional_relevant: false,
        emotional_time_based: false,
        emotional_stated: false,
     
        start_time: '',
        duration: '',
        selected_tags: [],
        notes: null,
        id: null
      },
      share_lesson: '0',
      search_member: '',
      selected_search: 'chatgpt',
      options: [
        { text: 'chatgpt', value: 'chatgpt' },
        { text: 'google', value: 'google' },
      ],
      minute_options: [
        { value: 5, text: '5 min' },
        { value: 10, text: '10 min' },
        { value: 15, text: '15 min' },
        { value: 20, text: '20 min' },
        { value: 25, text: '25 min' },
        { value: 30, text: '30 min' },
        { value: 35, text: '35 min' },
        { value: 40, text: '40 min' },
        { value: 45, text: '45 min' },
        { value: 50, text: '50 min' },
        { value: 55, text: '55 min' },
        { value: 60, text: '60 min' },
        { value: 65, text: '65 min' },
        { value: 70, text: '70 min' },
        { value: 75, text: '75 min' },
        { value: 80, text: '80 min' },
        { value: 85, text: '85 min' },
        { value: 90, text: '90 min' },
        { value: 95, text: '95 min' },
        { value: 100, text: '100 min' },
        { value: 105, text: '105 min' },
        { value: 110, text: '110 min' },
        { value: 115, text: '115 min' },
        { value: 120, text: '120 min' },
      ],
      success_msg: null,
      search_data: '',
      efficiency_and_result: {
        efficiency: 0,
        good: null,
        improve: null,
        notes: null,
      },
      new_team: '',
      teams: [],
      students: [],
      lessonGoals: [],
      lesson_date: null,
      classroom_link: null,
      didactical_models: [],
      allMethodologies: [],
      
    }
  },
  created() {
    Promise.all([
    this.getHeadData(),
    this.getAllDidacticalModels(),
    this.getModels(),
    this.getMethodologies()
  ])
  .then(() => {
    if (this.$route.params.id) {
      this.getEditData();
    }
  })
  .catch(error => {
    console.error("Error in loading data:", error);
  });

  },
  computed: {
    filteredStudents() {
      let filteredName = this.students.filter((student) => {
        return student.nome.toLowerCase().includes(this.search_member.toLowerCase()) ||
          student.cognome.toLowerCase().includes(this.search_member.toLowerCase());
      })

      return filteredName
    },
    filteredGoals() {
      let filtered_goals = this.lessonGoals.sort((a, b) => {
        let t1 = a.start_time.split(":");
        let sec1 = t1[0] * 3600 + t1[1] * 60 + t1[2] * 1;
        let t2 = b.start_time.split(":");
        let sec2 = t2[0] * 3600 + t2[1] * 60 + t2[2] * 1;
        return sec1 - sec2;
      })
      return filtered_goals;
    },
  },
  methods: {
    ...mapActions([
      'getAllStudents',
      'createNewLesson',
      'lessonUpdate',
      'didacticalModelsGet',
      'getSubjectClassTopic',
      'addDidacticalModel',
      'getMyModels',
      'methodologiesGet',
      'getLessonData'
    ]),
    async getHeadData() {
      await this.getSubjectClassTopic(this.$route.params.id)
        .then((res) => {
          this.classes = res.data.classes
          this.subject = res.data.subject
        })
    },
    async getMethodologies() {
      await this.methodologiesGet()
        .then(res => {
          this.allMethodologies = res.data
        })
    },
    async getAllDidacticalModels() {
      await this.didacticalModelsGet()
        .then((res) => {
          this.didactical_models = res.data
        })
    },
    onClassChange(id) {
      this.getAllStudents(id)
        .then((res) => {
          this.addTeamError = null
          this.students = res.data
        })
      const new_class = this.classes.find(c => c.id == id);
      console.log(this.classes)
      console.log(id)
      if(new_class && new_class.idMaterie) this.filteredSubject = this.subject.filter(s => new_class.idMaterie.includes(s.id.toString()));
      else this.filteredSubject = this.subject;
    },
    onContext(ctx) {
      // console.log(ctx.selectedFormatted, '')
      // console.log(ctx.selectedYMD, '')
    },
    async getModels() {
      await this.getMyModels()
        .then(res => {
          this.my_models = res.data
        })
    },
    addGoal() {
      this.didactical_error = !this.goal.didactical_objective_content || this.goal.didactical_objective_content.trim() === '';
      this.emotional_error = !this.goal.emotional_objective_content || this.goal.emotional_objective_content.trim() === '';

      if(this.didactical_error || this.emotional_error) return;

      this.goal.id = this.lessonGoals.length ? this.lessonGoals.length + 1 : 1;
      this.lessonGoals.push(Object.assign({}, this.goal));
      this.goal = {
        name:'',
        didactical_objective_content: '',
        didactical_methodologies: [],
        didactical_assigned_teams: [],
        didactical_award: '',
        didactical_specific: false,
        didactical_misurable: false,
        didactical_achievable: false,
        didactical_relevant: false,
        didactical_time_based: false,
        didactical_stated: false,

        emotional_objective_content: '',
        emotional_methodologies: [],
        emotional_assigned_teams: [],
        emotional_award: '',
        emotional_mood_needed: '',
        emotional_specific: false,
        emotional_misurable: false,
        emotional_achievable: false,
        emotional_relevant: false,
        emotional_time_based: false,
        emotional_stated: false,
      
      start_time: '',
      duration: '',
      selected_tags: [],
      notes: null,
      id: null
      }
    },
    editGoal(goal) {
      let btn = document.getElementById('goal_btn')
      if (btn.classList.contains('collapsed')) {
        btn.click()
      }
      this.goal_btn_status = true
      this.goal.name = goal.name
      this.goal.start_time = goal.start_time
      this.goal.duration = goal.duration
      this.goal.selected_tags = goal.selected_tags
      this.goal.id = goal.id
    },
    createNewGoal() {
      let btn = document.getElementById('goal_btn')
      if (btn.classList.contains('collapsed')) {
        btn.click()
      }
      this.goal.name = ''
      this.goal.start_time = ''
      this.goal.duration = ''
      this.goal.selected_tags = []
      this.goal.id = null
      this.goal_btn_status = false
    },
    updateGoal() {
      this.lessonGoals.forEach((goal) => {
        if (goal.id == this.goal.id) {
          goal.name = this.goal.name
          goal.start_time = this.goal.start_time
          goal.duration = this.goal.duration
          goal.selected_tags = this.goal.selected_tags
        }
        setTimeout(() => {
          this.goal.name = ''
          this.goal.start_time = ''
          this.goal.duration = ''
          this.goal.selected_tags = []
          this.goal.id = null
          this.goal_btn_status = false
        }, 1000)
      })
      let btn = document.getElementById('goal_btn')
      btn.click()
    },
    editLesson() {
      this.titleStatus = !this.titleStatus
    },
    changeTitle() {
      this.titleStatus = !this.titleStatus
    },
    openBar() {
      this.right_bar = !this.right_bar
    },
    addTeamModal() {
      this.addTeamError = null
      if (this.lesson_head.class) {
        this.$refs['team-modal'].toggle('#add_team-btn')
        this.new_team = ''
      } else {
        this.addTeamError = 'Pleas add class.'
      }
    },
    addNewTeam() {
      this.teams.push({
        id: this.teams.length ? this.teams.length + 1 : 1,
        saved: false,
        team_name: this.new_team,
        members: []
      })
      this.addTeamModal()
    },
    changeTeam() {
      this.search_member = ''
    },
    addStudent(team, student) {
      const { members } = team;
      student.selected = `${team.id}--${student.id}`
      members.push(Object.assign({}, student))
    },
    removeStudent(team, student) {
      student.selected = null
      team.members = team.members.filter((member) => member.id !== student.id)
    },
    addAllStudents(team) {
      const { members } = team;
      this.students.forEach(s => {
        if (s && !members.some(m => m.id === s.id)) members.push(Object.assign({}, s))
      });
    },
    removeAllStudents(team) {
      team.members = [];
    },
    checkActiveStudent(members, id) {
      return members.some((member) => {
        if (member && member.id === id) {
          return true
        }
      })
    },
    checkActiveAllStudents(members) {
      let result = true;
      this.students.forEach(s => {
        if (!members.some(m => m && m.id === s.id)) result = false;
      });
      return result;
    },
    addDidacticalMethod(method, goal) {
      goal.didactical_methodologies.push(Object.assign({}, method));
    },
    removeDidacticalMethod(method, goal) {
      goal.didactical_methodologies = goal.didactical_methodologies.filter(m => m.id !== method.id);
    },
    addEmotionalMethod(method, goal) {
      goal.emotional_methodologies.push(Object.assign({}, method));
    },
    removeEmotionalMethod(method, goal) {
      goal.emotional_methodologies = goal.emotional_methodologies.filter(m => m.id !== method.id);
    },
    addMethod(method, obj) {
      obj.methodologies.push(Object.assign({}, method));
    },
    removeMethod(method, obj) {
      obj.methodologies = obj.methodologies.filter(m => m.id !== method.id);
    },
    checkActiveMethod(methodologies, id) {
      if(!methodologies || methodologies.length < 1 || !id){ 
        return false;
      }else{
        return methodologies.some((method) => {
          if (method && method.id === id) {
            return true
          }
        })
      }

    },
    checkActiveTeam(teams, id) {
      if(!teams || teams.length < 1){ 
        return false;
      }else{
        return teams.some((team) => {
          if (team && team.id === id) {
            return true
          }
        })
      }
    },
    addDidacticalTeam(goal, team) {
      const { didactical_assigned_teams } = goal;
      team.selected = `${goal.id}--${team.id}`
      didactical_assigned_teams.push(Object.assign({}, team))
    },
    removeDidacticalTeam(goal, team) {
      team.selected = 0
      goal.didactical_assigned_teams = goal.didactical_assigned_teams.filter((item) => item.id !== team.id)
    },
    addEmotionalTeam(goal, team) {
      const { emotional_assigned_teams } = goal;
      team.selected = `${goal.id}--${team.id}`
      emotional_assigned_teams.push(Object.assign({}, team))
    },
    removeEmotionalTeam(goal, team) {
      team.selected = 0
      goal.emotional_assigned_teams = goal.emotional_assigned_teams.filter((item) => item.id !== team.id)
    },
    addTeam(goal, team) {
      const { assigned_teams } = goal;
      team.selected = `${goal.id}--${team.id}`
      assigned_teams.push(Object.assign({}, team))
    },
    removeTeam(goal, team) {
      team.selected = 0
      goal.assigned_teams = goal.assigned_teams.filter((item) => item.id !== team.id)
    },
    getEditData() {
      const lessonId = this.$route.params.id;
      this.getLessonData(lessonId)
        .then((res) => {
          if (res) {
            this.lesson_data = res.data
            this.lesson_title = res.data.lesson_title
            this.teacher_purpose = res.data.teacher_purpose;
            this.student_goals = res.data.student_goals
            this.classroom_link = res.data.classroom_link
            this.teams = res.data.team
            this.teams.forEach(t => t.saved = true)
            this.lessonGoals = res.data.goalandinfo
            this.efficiency_and_result = res.data.efficieanceandresult
            this.lesson_head.class = res.data.class_id
            this.lesson_head.subject = res.data.subject_id
            this.lesson_head.topic = res.data.topic
            this.share_lesson = res.data.share_lesson
            this.lesson_date = res.data.lesson_date
            this.onClassChange(this.lesson_head.class)
            this.lessonGoals.forEach(g => {
              g.didactical_methodologies = g.didactical_methodologies.split(",").map(assignedM => this.didactical_models.find(d => d.id == assignedM)).filter(d => d);
              g.emotional_methodologies = g.emotional_methodologies.split(",").map(assignedM => this.didactical_models.find(d => d.id == assignedM)).filter(d => d);
              g.didactical_assigned_teams = g.didactical_assigned_teams.split(",").map(assignedT => this.teams.find(t => t.id == assignedT)).filter(d => d);
              g.emotional_assigned_teams = g.emotional_assigned_teams.split(",").map(assignedT => this.teams.find(t => t.id == assignedT)).filter(d => d);

              g.didactical_specific = g.didactical_specific == 1 ? true : false;
              g.didactical_misurable = g.didactical_misurable == 1 ? true : false;
              g.didactical_achievable = g.didactical_achievable == 1 ? true : false;
              g.didactical_relevant = g.didactical_relevant == 1 ? true : false;
              g.didactical_time_based = g.didactical_time_based == 1 ? true : false;
              g.didactical_stated = g.didactical_stated == 1 ? true : false;
              g.emotional_specific = g.emotional_specific == 1 ? true : false;
              g.emotional_misurable = g.emotional_misurable == 1 ? true : false;
              g.emotional_achievable = g.emotional_achievable == 1 ? true : false;
              g.emotional_relevant = g.emotional_relevant == 1 ? true : false;
              g.emotional_time_based = g.emotional_time_based == 1 ? true : false;
              g.emotional_stated = g.emotional_stated == 1 ? true : false;
            })

          }
        })
    },
    saveLesson() {
      const failedLessons = this.lessonGoals.filter(l => l.emotional_objective_content.trim() === '' || l.didactical_objective_content.trim() === '');

      this.saveError = [];

      if(failedLessons.length>0) this.saveError.push("Error for lesson " + failedLessons[0].name + ": Emotional and/or Didactical objective cannot be empty");
      if(!this.teacher_purpose || this.teacher_purpose.trim() === "") this.saveError.push("Teacher's purpose cannot be empty");
      if(!this.student_goals || this.student_goals.trim() === "") this.saveError.push("Student's goals cannot be empty");
      if(!this.classroom_link || this.classroom_link.trim() === "") this.saveError.push("Classroom lesson's link cannot be empty");
      if(!this.lesson_head || !this.lesson_head.class) this.saveError.push("Class cannot be empty");

      if(this.saveError.length > 0) return;

      let sendData = {
        teacher_purpose: this.teacher_purpose,
        student_goals: this.student_goals,
        lesson_title: this.lesson_title,
        teams: this.teams,
        lesson_goals: this.lessonGoals,
        classroom_link: this.classroom_link,
        efficiency_and_result: this.efficiency_and_result,
        class_id: this.lesson_head.class,
        subject_id: this.lesson_head.subject,
        topic: this.lesson_head.topic,
        share_lesson: this.share_lesson,
        lesson_date: this.lesson_date
      }
      
      if(this.$route.params.id){
      //UPDATE
        sendData.id = this.$route.params.id;
        this.lessonUpdate(sendData)
          .then((res) => {
            if (res.data.status == 200) {
              this.$router.push('/my_lessons')
            }
          })
      }else{
        //CREATE
        this.createNewLesson(sendData)
          .then(res => {
            if (res.data.status == 200 || res.data.status == 201) {
              this.success_msg = res.data.message
              setTimeout(() => {
                this.success_msg = null
                this.$router.push('/my_lessons')
              }, 3000)
            }
          })
          .catch(err => {
            console.log(err, 'error')
          })
      }
    },
    linkModal() {
      this.$refs['link-modal'].toggle('#link-btn')
    },
    addLink() {
      this.linkModal()
    },
    isPurposeAndInformationCompiled() {
      return this.teacher_purpose && this.student_goals;
    },
    methodModal(method) {
      this.selectedMethod = null
      if (method) {
        this.selectedMethod = method
      } else {
        this.selectedMethod = null
      }
      this.$refs['method-modal'].toggle('#method-btn')
    }
  }
}

</script>
